import { stringify } from 'query-string';

// const API_URL = 'http://127.0.0.1:3000';
const API_URL = 'https://fridayapi.getjuly.com';

export const fetchAPI = (url, headers ) => {
  return fetch(`${API_URL}/${url}`, {
    headers: new Headers({
      'Authorization': localStorage.getItem('token'),
      'Content-Type': 'application/json',
      ...headers
    })
  }
  ).then(response => { 
    return response.json()
  })
}

export const postAPI = (url, body, headers ) => {
  return fetch(`${API_URL}/${url}`, {
    method: "POST", 
    body: JSON.stringify(body),
    headers: new Headers({
      'Authorization': localStorage.getItem('token'),
      'Content-Type': 'application/json',
      ...headers
    })
  }
  ).then(response => { 
    return response.json()
  })
}
