

const URL = 'https://fridayapi.getjuly.com/authenticate'
// const URL = 'http://127.0.0.1:3000/authenticate'

const isAuthenticated = () => {
  let Authorization = localStorage.getItem('token');
  return Authorization !== 'undefined'
}

const authenticate = (params, cp) => {
  const { email, password } = params;
  const request = new Request(URL, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
  fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        return false
      }
      return response.json();
    })
    .then((json) => {
      if(json === false) {
        return false
      }
      console.log(json)
      const { auth_token } = json
      localStorage.setItem('token', auth_token);
      cp()
    });
}

const signout = (cb) => {
  localStorage.setItem('token', undefined);
  cb()
}

export default {authenticate, isAuthenticated, signout}