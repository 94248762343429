import React from 'react'

import DataTable from '../components/DataTable'

const Unfulfilled = () => {
  return (
    <DataTable url='unfulfilled' title="Unfulfilled" />
  )
}
export default Unfulfilled
