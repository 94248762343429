import React from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import Header from './components/Header'
import './App.css';
import Unfulfilled from './pages/Unfulfilled.js'
import Personalisation from './pages/Personalisation.js'
import Shipments from './pages/Shipments.js'
import Login from './pages/Login.js'
import AllOrders from './pages/AllOrders'
import Dash from './pages/Dash'

import auth from './helpers/authProvider'

// const fakeAuth = {
//   isAuthenticated: false,
//   authenticate(cb) {
//     this.isAuthenticated = true
//     setTimeout(cb, 100)
//   },
//   signout(cb) {
//     this.isAuthenticated = false
//     setTimeout(cb, 100)
//   }
// }

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    auth.isAuthenticated() === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
  )} />
)

const App = () => {
  return (
    <Router>
      <nav>
        <Header />
      </nav>
      <div>
        <PrivateRoute path="/" exact component={Dash} />
        <PrivateRoute path="/orders" exact component={AllOrders} />
        <PrivateRoute path="/personalisation" exact component={Personalisation} />
        <PrivateRoute path="/unfulfilled" exact component={Unfulfilled} />
        <PrivateRoute path="/shipments" exact component={Shipments} />
        <Route path="/login" component={Login}/>
      </div>
    </Router>
  )
}


export default App;
