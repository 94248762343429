import React, {useState} from 'react';
import styled from 'styled-components'

import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";
import JulyLogo from '../images/mobile/icons/July-green.svg'
import JulyDesktop from '../images/desktop/icons/july-white.svg'
import Menu from '../images/mobile/icons/menu.svg'
import auth from '../helpers/authProvider'

const Wrapper = styled.div`
	background: white;
`

const MenuItem = styled.a`
	height: 100%;
`

const Mobile = styled.div`
  width: 100%;
  display: block;
  @media (min-width: 1024px) {
    display: none;
  }
`

const Desktop = styled.div`
  width: 100%;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`

const DesktopNavContainer = styled.div`
  position: fixed;
  top: 24px;
  ${props => props.home ? 'top: 40px;' : '' }
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  padding: 23px 70px;
  color: white;
  transition: all 1s;
  ${props => props.home ? 'position: absolute;':'background: white;top: 0px;padding-top: 47px;'}
  ${props => props.offscreen ? 'top: -100px;' : ''}
`

const LogoLink = styled.a`
`

const MenuLink = styled.a`
	color: #FFFFFF;	
	font-size: 14px;	
	font-weight: 500;	
	line-height: 14px;
	margin: 0 24px;
  position: relative;
  cursor: pointer;
  text-decoration: none;
	${props => props.home ? '' : 'color: #475F4C;'}
`
const LogoImage = styled.img`
  min-width: 68px;
  min-height: 32px;
`
const AuthText = styled.p`
  color: black;
`

const AuthButton = withRouter(({ history }) => {
  return auth.isAuthenticated() ? 
  (
    <AuthText>
      <button className="button" onClick={() => {
        auth.signout(() => history.push('/'))
      }}>Sign out</button>
    </AuthText>
  ) :
  (
    <AuthText>Login</AuthText>
  )
})


const App = () => {
  let [menuOpen, setMenuOpen] = useState(false)
  const openMenu = () => { 
    setMenuOpen(true)
  }
  const closeMenu = () => { 
    setMenuOpen(false)
  }
  return (
    <Wrapper>
			<Mobile>
			<nav>
				<div className="container">
					<div className="navbar-brand">
						<LogoLink className="navbar-item">
							<LogoImage alt="july logo" src={JulyLogo} alt="Logo" />
						</LogoLink>
						<span className="navbar-burger" data-target="navbarMenuHeroA">
							<MenuItem className="navbar-item">
								<img style={{cursor: 'pointer'}} alt="nav icon" onClick={openMenu} className="nav-bar" src={Menu}/>
							</MenuItem>
						</span>
					</div>
					<div id="navbarMenuHeroA" className="navbar-menu">
						<div className="navbar-end">
							<span className="navbar-item">
								
							</span>
						</div>
					</div>
				</div>
			</nav>
			</Mobile>
			<Desktop> 
				<DesktopNavContainer >
					<div>
						<a href="/"> <LogoImage src={JulyLogo} /> </a>
					</div>
					<div>
						<MenuLink href="/orders"> Orders </MenuLink>
						<MenuLink href="/unfulfilled"> Unfulfilled </MenuLink>
						<MenuLink href="/personalisation"> Personalisation </MenuLink>
						<MenuLink href="/shipments"> Shipments </MenuLink>
					</div>
					<AuthButton />
				</DesktopNavContainer>
			</Desktop>
		</Wrapper>
  )
}


export default App;
