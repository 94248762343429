import React, {useState} from 'react'
import { Redirect } from "react-router-dom";
import auth from '../helpers/authProvider'

const Login = ({location}) => {
  let [redirectToReferrer, setRedirectToReferrer] = useState(false)
  let [email, setEmail] = useState('')
  let [password, setPassword] = useState('')
  const login = () => {
    auth.authenticate({
      email,
      password
    },
    () => {
      setRedirectToReferrer(true)
    })
  }
  const { from } = location.state || { from: { pathname: '/' } }
  if (redirectToReferrer === true) {
    return <Redirect to={from} />
  }
  return (
    <div>
      <p>You must log in to view the page</p>
      <input value={email} onChange={(e) => setEmail(e.currentTarget.value)} type="email"/>
      <input value={password} onChange={(e) => setPassword(e.currentTarget.value)} type="password"/>
      <button onClick={login}>Log in</button>
    </div>
  )
}
export default Login