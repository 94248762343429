import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import {fetchAPI, postAPI} from '../helpers/dataProvider'
import styled from 'styled-components'
import { async } from 'q';

Date.prototype.yyyymmdd = function() {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd
         ].join('/');
};


const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #00000099;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`
const Modal = styled.div`
  width: 60%;
  height: 60%;
  background: white;
  padding: 32px 16px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`
const ModalTable = styled.table`
`
const ModalTableRow = styled.tr`
`
const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

const ModalTableData = styled.td`
  padding: 2px 16px;
  padding-left: 0;
`
const ModalHeading = styled.h2`
`
const BookButton = styled.button`
float: right;
`

const rowData = (row) => {
  console.log(row)
    return {
        jarvisId: row.id,
        date: new Date(row.created_at).yyyymmdd(),
        id: row.name,
        skus: row.line_items,
        shipping: (row.shipping_lines && row.shipping_lines.length > 0) ? row.shipping_lines[0].title : '' ,
        name: (row.customer) ? `${row.customer.first_name} ${row.customer.last_name}` : '' ,
        tracking:(row.fulfillment_status) ? row.fulfillment_status  : row.fulfillment_tracking_number ,
        address: row.shipping_address ? `${row.shipping_address.city}, ${row.shipping_address.province_code || row.shipping_address.province}, ${row.shipping_address.zip}, ${row.shipping_address.country}`: ''
    }
}

const BookModal = ({order, setSelectedOrder }) => {
  let [location, setLocation] = useState('july-hq')
  let [quote, setQuote] = useState([])
  let [lineItemData, setLineItemData] = useState(order.line_items.map(val => {
    return {
      id: val.id,
      sku: val.sku,
      book: false,
      fulfill: false
    }
  }))
  const handleCancel = () => {
    setSelectedOrder(undefined)
  }
  const handleBook = async (courier_allocation) => {
    const res = await postAPI('book_single', ({
      id: order.name,
      location: location,
      courier_allocation,
      line_items: lineItemData,
      shipping_address: order.shipping_address
    }))
    setSelectedOrder(undefined)
  }
  const getQuote = async (upData) => {
    const lineData = upData || lineItemData
    const bookItems = lineData.filter(val => val.book).map(val => val.sku)
    if (bookItems.length > 0) {
      setQuote([])
      const quoteRes = await postAPI('quote_parcels', ({
        skus: bookItems ,//order.line_items.map(val => val.sku),
        shipping_address: order.shipping_address
      }))
      setQuote( JSON.parse( quoteRes.data )['response'] )
    } 
  }
  useEffect(() => {
    getQuote()
  }, [])
  const handleBookChanged = (e, key) => {
    setLineItemData(prev => {
      const newLineItemData = [...prev]
      newLineItemData[key] = {
        ...newLineItemData[key],
        book: !newLineItemData[key].book
      } 
      getQuote(newLineItemData)
      return newLineItemData
    })
  }
  const handleFulfillChanged = (e, key) => {
    console.log(e.currentTarget.checked)
    setLineItemData(prev => {
      const newLineItemData = [...prev]
      newLineItemData[key] = {
        ...newLineItemData[key],
        fulfill: !newLineItemData[key].fulfill
      } 
      console.log(newLineItemData)
      return newLineItemData
    })
  }
  return <>
    <ModalContainer>
      <Modal>
        <div>
          <ModalHeading> Book Line Items </ModalHeading>
          <br/>
          <div>
            <b>Location:</b>   
            <select onChange={(e) => setLocation(e.currentTarget.value)} value={location}>
              <option value="july-hq">July HQ</option>
              {/* <option value="sydney-wh">Sydney WH</option> */}
            </select>
          </div>
          <br/> 
          <ModalTable>
            <ModalTableRow> 
              <ModalTableData> <b>Book</b> </ModalTableData>
              <ModalTableData> <b>Fulfill</b> </ModalTableData>
              <ModalTableData> <b>Fulfillment Status</b> </ModalTableData>
              <ModalTableData> <b>SKU</b> </ModalTableData>
            </ModalTableRow>
            {
              order.line_items.map((line_item, key) => <>
                <ModalTableRow> 
                  <ModalTableData> {line_item.fulfillment_status === null && <input onChange={(e) => handleBookChanged(e, key)} checked={lineItemData[key].book} type="checkbox"/>} </ModalTableData>
                  <ModalTableData> {line_item.fulfillment_status === null && <input onChange={(e) => handleFulfillChanged(e, key)} checked={lineItemData[key].fulfill} type="checkbox"/>} </ModalTableData>
                  <ModalTableData> {line_item.fulfillment_status || 'unfulfilled'} </ModalTableData>
                  <ModalTableData> {line_item.sku} x{line_item.quantity} </ModalTableData>
                </ModalTableRow>
              </>)
            }
          </ModalTable>
        </div>
        <div>
          <SpaceBetweenDiv> 
            <BookButton onClick={handleCancel}> Cancel </BookButton>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <div className="dropdown" style={{overflow: 'visable !important'}}>
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Book with:</button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">  
                  {
                    quote.length > 0 ? quote.map((val) => 
                    <div style={{margin: '4px 12px', cursor: 'pointer'}} onClick={() => handleBook(val.courier_type)}>
                      
                      {
                        val.success && (
                          <>
                            <span style={{fontWeight: 500}}>{val.courier_type}</span>
                            {val.quotes.map(newVal => <>
                              <span style={{fontSize: 10}}>${newVal.price}</span>
                              <span style={{marginRight: 8}}/>
                              <span style={{fontSize: 8}}>{newVal.estimated_transit_time}</span>
                            </>)}
                          </>
                        )
                      }
                    </div>) : <> Loading... </>
                  }
                </div>
              </div>
            </div>
          </SpaceBetweenDiv>
        </div>
      </Modal>
    </ModalContainer>
  </>
}

const Table = ({url, name}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageNum, setPage] = useState(1)
  const [selectedOrder, setSelectedOrder] = useState(undefined)
  const [order, setOrder] = useState(undefined)

  const fetchUsers = async page => {
    setLoading(true);
    setPage(page)

    const data = await fetchAPI(url, { page, perPage, delay: 1 })
    setData(data.data.map(row => rowData(row)))
    setTotalRows(data.total);
    setLoading(false);
  };
  const handlePageChange = page => {
    fetchUsers(page);
  };
  const handleClearRows = () => {
    setToggledClearRows(prev => !prev)
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setLoading(true);
    setPage(page)

    const data = await fetchAPI(url, { page, perPage: newPerPage, delay: 1 })
    setData(data.data.map(row => rowData(row)))
    setTotalRows(data.total);
    setLoading(false);
  };
  const handleBooking = async (carrier) => {
    const data = await postAPI('bulk_orders', ({
      ids: selectedRows.map(row => row.id),
      courier_allocation: carrier
    }))
    handleClearRows()
    await fetchUsers(pageNum)
  }
  const handleSelectedOrder = async (id) => {
    const data = await postAPI('get_order', ({id}))
    setOrder(data.order)
    setSelectedOrder(id)
  }
  const handleCancelFulfilment = async (name) => {
    const data = await postAPI('cancel_fulfilment', ({
      id: name
    }))
    handleClearRows()
    await fetchUsers(pageNum)
  }
  const handleBook = async (id) => {
    handleSelectedOrder(id)
  }
  const columns = [
    {
      name: 'Date Created',
      selector: 'date',
      sortable: true,
      width: '85px'
    },
    {
      name: 'Shopify ID',
      selector: 'id',
      sortable: true,
      width: '130px',
    },
    {
      name: 'Fulfillment',
      width: '95px',
      selector: 'tracking',
      button: true,
      cell: row=>
      <div>
        {row.tracking === undefined ? 'unfulfilled': <b>{row.tracking}</b>}
        {
          row.tracking !== undefined && 
          <>
            <br/> 
            <button onClick={() => {
              handleCancelFulfilment(row.id)
            }}> Cancel </button>
          </>
        }
        
      </div>
    },
    {
      name: 'shipping',
      width: '160px',
      selector: 'shipping',
    },
    {
      name: 'name',
      width: '150px',
      selector: 'name',
    },
    {
      name: 'Address',
      selector: 'address',
      width: '250px',
    },
    {
      name: 'SKUs',
      selector: 'skus',
      width: '170px',
      button: true,
      cell: row => 
      <div> 
        {row.skus.map((val, key) => <div key={key} style={{textAlign: 'left'}} href="#"> {val.sku} x{val.quantity} </div>)}
      </div>
    },
    {
      name: 'Book',
      width: '80px',
      selector: 'book',
      button: true,
      cell: row=> <button onClick={() => handleBook(row.id) }> Book </button>
    },
  ];

  useEffect(() => {
    fetchUsers(1)
  },[])
  return (
    <div className="main-container">
      {
        selectedOrder !== undefined && <BookModal setSelectedOrder={setSelectedOrder} order={order} />
      }
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <div className="dropdown" style={{overflow: 'visable !important'}}>
          <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Book with:</button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">  
            <div style={{margin: '4px 12px', cursor: 'pointer'}} onClick={() => handleBooking('eParcel')}> eParcel </div>
            <div style={{margin: '4px 12px', cursor: 'pointer'}} onClick={() => handleBooking('EparcelExpress')}> eParcel Express </div>
            <div style={{margin: '4px 12px', cursor: 'pointer'}} onClick={() => handleBooking('Tnt')}> TNT </div>
          </div>
        </div>
      </div>
      {
        <DataTable 
          title={name} 
          columns={columns} 
          data={data}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          selectableRows 
          onRowSelected={(e) => setSelectedRows(e.selectedRows)}
          paginationRowsPerPageOptions={[10,25,50,100, 250]}
          paginationPerPage={100}
          clearSelectedRows={toggledClearRows}
        />
      }
    </div>
  )
}
export default Table