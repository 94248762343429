import React from 'react'

import DataTable from '../components/DataTable'

const AllOrders = () => {
  return (
    <DataTable url='orders' title="All Orders" />
  )
}
export default AllOrders
